#form-file-upload {
  height: 32vh;
  min-height: 32vh;
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 5%;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  text-overflow: ellipsis;
  border-radius: 0.5rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  font-family: "MontserratRegular";
  text-decoration-line: underline;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
