.pointer {
  cursor: pointer;
}

.ant-modal-footer {
  display: none;
}

.ant-select-selector {
  height: 50px !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  margin-top: 5px;
}

.btn-square {
  border-radius: 0;
}

h1,
h2,
h3,
h4,
p,
span {
  font-family: "MontserratRegular";
}

@font-face {
  font-family: "LemonMilk";
  src: local("LemonMilk"),
    url("./assets/fonts/LEMONMILK-Regular.otf") format("truetype");
}

@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

.font-face-lm {
  font-family: "LemonMilk";
}

.font-face-mr {
  font-family: "MontserratRegular";
}

.btn-turq {
  all: unset;
  background-color: #00c1b7;
  border-radius: 100px;
  font-family: "MontserratRegular";
  border: 1px solid #00c1b7;
  font-size: small;
  color: white;
  padding: 0.5rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
}

.btn-turq:hover {
  transition: 0.5s;
  background-color: white;
  color: #00c1b7;
}

.btn-turq:disabled,
.btn-turq[disabled] {
  background-color: #99afae;
  cursor: not-allowed;
}

a {
  text-decoration: none;
}

.btn-red-txt {
  all: unset;
  background-color: transparent;
  border-radius: 100px;
  font-family: "MontserratRegular";
  font-size: small;
  color: rgb(255, 60, 60);
  padding: 0.5rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.btn-black-txt {
  all: unset;
  background-color: transparent;
  border-radius: 100px;
  font-family: "MontserratRegular";
  font-size: small;
  color: rgb(0, 0, 0);
  padding: 0.5rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.tag-blue {
  all: unset;
  background-color: #3b7cff;
  border-radius: 100px;
  font-family: "MontserratRegular";
  color: white;
  padding: 0.5rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.tag-blue-sm {
  all: unset;
  background-color: #3b7cff;
  border-radius: 100px;
  font-family: "MontserratRegular";
  color: white;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 90%;
}

.img-wrap {
  position: relative;
}

.img-wrap .close {
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 100;
  opacity: 100%;
}

.btn-orange {
  all: unset;
  background-color: #fc5a00;
  border-radius: 100px;
  font-family: "MontserratRegular";
  border: 1px solid #fc5a00;
  font-size: small;
  color: white;
  padding: 0.5rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
}

.btn-orange:hover {
  transition: 0.5s;
  background-color: white;
  color: #fc5a00;
}

.btn-orange:disabled,
.btn-orange[disabled] {
  background-color: #cc0b0b;
  cursor: not-allowed;
}

.horizontal-scrollable > .row-pet {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable > .row-pet > .col-xs-4 {
  display: inline-block;
  float: none;
}
