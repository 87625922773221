.tbl-container {
  max-width: fit-content;
  max-height: fit-content;
}

.tbl-fixed {
  overflow-x: scroll;
  overflow-y: scroll;
  height: fit-content;
  max-height: 70vh;
  margin-top: 3vh;
}

table {
  min-width: max-content;
  border-collapse: separate;
  border: none;
}

table th {
  position: sticky;
  top: 0px;
  background: blue;
  text-align: center;
  color: black;
  border: 1px solid purple;
  font-size: 16px;
  /* z-index: 1000; */
}

table td {
  background-color: bisque;
  text-align: center;
  border: 1px solid palevioletred;
}

.table-vertical-header th:first-child
/* .table-vertical-header td:first-child */
/* th:nth-child(2),
td:nth-child(2) { */ {
  position: sticky;
  left: 0px;
  background-color: orange;
  z-index: 1;
}
