.page-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-pagination {
  background-color: transparent;
  color: #fc5a00;
  margin: 0rem 1rem;
  padding: 0.3rem;
  cursor: pointer;
  transition: 0.3s;
}

.icon-pagination:hover {
  transition: 0.3s;
  background-color: transparent;
  color: black;
}

.icon-pagination:disabled {
  background-color: transparent;
  color: gray;
}
