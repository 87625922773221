.img-holder {
  width: 50%;
  height: 150px;
}

.data-holder {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 1rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px 10px 10px 0px;
}

.hint {
  font-size: 0.7em;
}

.btn-add {
  font-size: 1.5em;
  margin-top: 2rem;
}

.card-holder {
  position: relative;
}

.trash-icon {
  position: absolute;
  background-color: #00c1b7;
  padding: 0.5rem;
  top: -15px;
  left: -15px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}

.trash-icon:hover {
  transition: 0.2s;
  background-color: white;
  color: #00c1b7;
}
