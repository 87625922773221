.image-holder {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-preview {
  width: 40vw;
  height: 30vw;
  border-radius: 10px;
  object-fit: cover;
}

.no-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid gray;
  background-color: lightgray;
  transition: 0.5s;
}

.no-image > .icon-image {
  transition: 0.5s;
}

.no-image:hover {
  transition: 0.5s;
  background-color: white;
}

.no-image:hover > .icon-image {
  transition: 0.5s;
  scale: 1.2;
}

.input {
  display: none;
}
