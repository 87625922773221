.navbar {
  background-color: #f8f9fa;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.menu-bars {
  margin-left: 1rem;
  font-size: 1rem;
  background: none;
}

.close-icon {
  text-decoration: none;
  font-size: 2rem;
  background: none;
  color: white;
}

.nav-menu {
  background-color: #fc5a00;
  width: 290px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 1000;
  box-shadow: 9px 0px 24px 0px rgba(0, 0, 0, 0.75);
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex-start;
  align-items: center;
  padding: 4px 0px 4px 0px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 20px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  text-decoration: none;
  background-color: white;
  color: #fc5a00;
}

.icon-btn:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: white;
  color: #231f20;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #fc5a00;
  width: 100%;
  height: 80px;
  display: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.circle {
  border-radius: 50%;
  background-color: black;
  min-height: 100px;
}

#dropdown-basic::after {
  display: none;
}
