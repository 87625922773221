.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar__navigation {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.react-calendar__navigation button {
  color: black;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  border: 0px;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__weekdays__weekday--weekend {
  display: flex;
  justify-content: center;
}

abbr[data-original-title],
abbr[title] {
  text-align: center;
  text-decoration: none !important;
  cursor: default !important;
  text-transform: capitalize;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  transition: 0.5s;
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__month-view__days__day {
  border: 0px;
  padding: 1rem;
}

.react-calendar__tile.react-calendar__tile--now {
  background-color: rgba(252, 90, 0, 0.5);
  color: white;
  border-radius: 0px;
}

.react-calendar__tile.react-calendar__tile--now:hover {
  transition: 0.5s;
  background-color: rgba(252, 90, 0, 1);
}
.react-calendar__tile {
  transition: 0.5s;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: gray;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  transition: 0.5s;
  background: #f8f8fa;
  color: #fc5a00;
  border-radius: 0px;
}
.react-calendar__tile--now {
  transition: 0.5s;
  background: #6f48eb33;
  border-radius: 0px;
  font-weight: bold;
  color: #6f48eb;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  transition: 0.5s;
  background: #fc5a00;
  border-radius: 0px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  transition: 0.5s;
  background: #f8f8fa;
  border: 0px;
}
.react-calendar__tile--active {
  transition: 0.5s;
  background: #fc5a00;
  border-radius: 0px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  transition: 0.5s;
  background: #fc5a00;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  background: #fc5a00;
  border: 0px;
  color: white;
}
.react-calendar__tile--rangeEnd {
  background: #fc5a00;
  border: 0px;
  color: white;
}
