.stool-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0rem;
}

.image-holder {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0rem 2rem;
}

.image {
  width: 100%;
  border-radius: 20px;
}

.score {
  position: absolute;
  background-color: #fc5a00;
  color: white;
  padding: 1.5rem;
  top: -20px;
  left: -50px;
  border-radius: 50%;
}

.icon {
  padding: 0.8rem;
  color: white;
  background-color: #3b7cff;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s;
}

.icon:hover {
  transition: 0.5s;
  color: #3b7cff;
  background-color: white;
  cursor: pointer;
}

.register {
  margin-left: 1rem;
  margin-bottom: 0;
  padding: 1rem;
  color: white;
  background-color: #3b7cff;
  border-radius: 30px;
  cursor: pointer;
}

.register:hover {
  transition: 0.5s;
  color: #3b7cff;
  background-color: white;
  cursor: pointer;
}

.register > .icon-register {
  color: white;
}

.register:hover > .icon-register {
  transition: 0.5s;
  color: #3b7cff;
  cursor: pointer;
}

.last-stool-image-holder {
  width: 40%;
  position: relative;
}

.pet-image {
  width: 40vw;
  height: 30vw;
  object-fit: cover;
  border-radius: 10px;
}

.icon-pagination {
  color: #fc5a00;
  margin: 0rem 1rem;
  padding: 0.3rem;
  cursor: pointer;
  transition: 0.3s;
}

.icon-pagination:hover {
  transition: 0.3s;
  background-color: transparent;
  color: black;
}

@media screen and (max-width: 800px) {
  .image-holder {
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0rem 2rem;
  }

  .last-stool-image-holder {
    width: 80%;
    height: auto;
    position: relative;
  }
}
